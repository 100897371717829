<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">智能制造体系构建</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP" style="text-indent: 2em">
        如果您的企业正在将现时的制造升级为智能制造，那么需要有配套的数字化体系，两者的关系如图：
      </p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/37.png"
        class="SonImg"
      />
      <br />
      <br />
      <p class="SmallP" style="text-indent: 2em">
        智能制造是数字体系的一部分，数字体系也是智能制造的一部分，相互依赖，互有交集；以智能制造带动数字体系的建设，以数字体系建设促进智能制造。在某些基础性建设方面本质上别无二致，例如，业务软件、作业流程、流程优化技术、数据链、与数字基础设施的融合，等等。如果要说区别，数字体系是“信息场景系统（Cyber
        Scene Systems,CSS）”，而智能制造则是“信息物理系统（Cyber Physical
        System,CPS）”。下表是一些匹配内容的列举：
      </p>
      <div class="ImgP">
        <p class="SmallP" style="width: auto">智能制造与数字体系的匹配</p>
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/38.png"
          class="SonImgW"
        />
      </div>
      <p class="SmallP" style="text-indent: 2em">
        智能制造需要数字体系的支持，原则是：不在落后的作业场景上搞自动化；不在落后的流程上搞信息化；不在落后的体系上搞数字化；不在落后的数据基础上搞智能化。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>